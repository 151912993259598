<template>
    <div class="mb-12 mb-xl-0">
        <v-row align="center" justify="end" class="row--x-small">
            <v-col cols="auto">
                <v-btn text color="grey-6" @click.native="navigate">
                    <template v-if="accessToken">
                        <span>{{ $t("common.로그아웃") }}</span>
                    </template>
                    <template v-else>
                        <span>{{ $t("common.로그인") }}</span>
                    </template>
                </v-btn>
            </v-col>
            <v-col cols="auto" v-if="!accessToken">
                <span class="line"></span>
            </v-col>
            <v-col cols="auto" v-if="!accessToken">
                <v-btn to="/join" text color="grey-6" >
                    회원가입
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    components: {
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    methods: {
        navigate(){
            if(this.accessToken){
                this.$store.dispatch("logout");
            }
            else{
                this.$router.push(`/login`);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.line{
    display: block;
    width: 1px;
    height: 12px;
    background-color: var(--border-color);
}
::v-deep .v-btn.v-btn--text{
    height: auto;
    font-size: 1.4rem;
    padding: 4px;
    min-width: 0;
}
</style>