var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-12 mb-xl-0"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "grey-6"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.navigate.apply(null, arguments);
      }
    }
  }, [_vm.accessToken ? [_c('span', [_vm._v(_vm._s(_vm.$t("common.로그아웃")))])] : [_c('span', [_vm._v(_vm._s(_vm.$t("common.로그인")))])]], 2)], 1), !_vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]) : _vm._e(), !_vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": "/join",
      "text": "",
      "color": "grey-6"
    }
  }, [_vm._v(" 회원가입 ")])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }